import {
    initHoldEventListeners,
    displayCurrentProcesses,
    displayPackedProducts,
    displayFinishedProcesses,
} from "./interaction";

// source: https://mixkit.co/free-sound-effects/notification/
const notificationSound = new Audio("/sounds/notification.wav");

export async function processOrders(playSound) {
    const store = Alpine.store("refueat");

    // check auth first, if not authorized, dont do anything...
    if (!store.auth.jwts[store.curShop.url]) {
        return;
    }

    let date = document.getElementById("date").value;
    if (!date) {
        alert("Bitte Datum auswählen!");
        return;
    }

    const german_date =
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4);
    document.querySelector(".print-date").innerHTML = "- " + german_date;

    if (store.currentlyProcessingOrdersFor == store.curShop.name) {
        console.log("abort processOrders: already processing for this shop");
        return;
    }
    store.currentlyProcessingOrdersFor = store.curShop.name;
    store.loading = true;

    // if we changed date (or its the first fetch) we reset everything
    if (store.curDate != date) {
        // hide table if there is no entry for this date yet, otherwise show already existing data
        if (!store.orderData[store.curShop.url]?.[date]) {
            store.showTable = false;
        }
        // store.orderData = {};
        // store.productQuantities = {};
        store.curDate = date;

        store.fullyClickedRowsTotalCells = [];
        store.fullyClickedFinishedRowsTotalCells = [];
        store.fullyPreparingRowsTotalCells = [];
        store.fullyFinishedRowsTotalCells = [];
        store.fullyPackedRowsTotalCells = [];

        for (const worker of store.workers) {
            worker.isPreparing = false;
            worker.preparingCells = [];
            worker.preparingProductsOrdersQuantities = {};
        }

        // init upcoming week
        let dateObj = new Date();
        const amountOfDays = 14;
        for (let i = 0; i < amountOfDays; i++) {
            let newDateObj = new Date(dateObj);
            store.daysOfTheWeek[i] = [];
            store.daysOfTheWeek[i][0] = newDateObj.toLocaleDateString("de-DE", {
                weekday: "short",
            });
            store.daysOfTheWeek[i][1] = newDateObj.toLocaleDateString("de-DE", {
                day: "2-digit",
                month: "2-digit",
            });
            let iso = newDateObj.toLocaleDateString("sv");
            // days.push(iso)
            store.daysOfTheWeek[i][2] = iso;
            dateObj.setDate(dateObj.getDate() + 1);
        }
        store.daysOfTheWeek[0][0] = "Heute";
        store.daysOfTheWeek[1][0] = "Morgen";
    } else if (!store.orderData[store.curShop.url]?.[store.curDate]?.length) {
        // if store has no data at all, initialize it
        if (!store.orderData[store.curShop.url])
            store.orderData[store.curShop.url] = {};

        if (!store.orderData[store.curShop.url][store.curDate])
            store.orderData[store.curShop.url][store.curDate] = [];

        store.showTable = false;
        store.loading = true;
    }

    // fetch the bestandteile from backend ONCE - static
    let components_url;
    if (!store.componentPosts[store.curShop.url])
        store.componentPosts[store.curShop.url] = [];
    if (!store.componentPosts[store.curShop.url].length) {
        components_url =
            "https://" +
            store.curShop.url +
            "/wp-json/wp/v2/bestandteil/?per_page=100";
    }

    // fetch the product categories from backend ONCE - static
    let categories_url;
    if (!store.productCategories[store.curShop.url])
        store.productCategories[store.curShop.url] = [];
    if (!store.productCategories[store.curShop.url].length) {
        categories_url =
            "https://" +
            store.curShop.url +
            "/wp-json/wc/v3/products/categories/?per_page=100";
    }

    // fetch products that are costly ONCE - static
    let costlyProducts_url;
    if (!store.costlyProducts[store.curShop.url])
        store.costlyProducts[store.curShop.url] = [];
    if (!store.costlyProducts[store.curShop.url].length) {
        costlyProducts_url =
            "https://" +
            store.curShop.url +
            "/wp-json/wc/v3/products/" +
            "?per_page=100&meta_key=_costly_product&meta_value=yes&_fields=id,name,meta_data";
    }

    // fetch the product ids for products with drinks/hot dishes ONCE - static
    // TODO: make dynamic (via setting in shop)
    let hotProducts_url;
    if (!store.hotOrDrinkIds[store.curShop.url])
        store.hotOrDrinkIds[store.curShop.url] = [];
    if (!store.hotOrDrinkIds[store.curShop.url].length) {
        hotProducts_url =
            "https://" +
            store.curShop.url +
            "/wp-json/wc/v3/products?per_page=100&category=47,71&_fields=id";
    }

    // fetch the product ids for products with personnel/dishes ONCE - static
    // TODO: make dynamic (via setting in shop)
    let serviceProducts_url;
    if (!store.personnelOrDishIds[store.curShop.url])
        store.personnelOrDishIds[store.curShop.url] = [];
    if (!store.personnelOrDishIds[store.curShop.url].length) {
        // define which skus are to be excluded -> get from options page? for now static
        const excludedSKUs = ["GS-EINWEG"];

        const excludedProducts_url =
            "https://" +
            store.curShop.url +
            "/wp-json/refueat/v1/products-by-skus/?skus=" +
            JSON.stringify(excludedSKUs);

        let excludedIDs;
        let response = await fetchAllPages(excludedProducts_url);
        if (response[0].data?.error || response[0].data?.status == 404) {
            console.error(response[0]);
            excludedIDs = [];
        } else {
            excludedIDs = Object.values(response[0]);
        }

        serviceProducts_url =
            "https://" +
            store.curShop.url +
            "/wp-json/wc/v3/products?per_page=100&category=70&_fields=id&parent_exclude=" +
            excludedIDs.join(",") +
            "&exclude=" +
            excludedIDs.join(",");
        // serviceProducts_url =
        //     "https://" +
        //     store.curShop.url +
        //     "/wp-json/wc/v3/products?per_page=100&category=70&_fields=id&parent_exclude=1841&exclude=1841";
    }

    function handleComponentsResponse(response) {
        store.componentPosts[store.curShop.url] = response;
    }
    function handleCategoriesResponse(response) {
        response.sort(function (a, b) {
            return a.menu_order - b.menu_order;
        });
        store.productCategories[store.curShop.url] = response
            .filter((cat) => cat.count > 0 || cat.slug == "alle-produkte")
            .map(
                (cat) =>
                    (cat = {
                        slug: cat.slug,
                        name: cat.name,
                    })
            );
        store.productCategories[store.curShop.url].push({
            slug: "no-category",
            name: "Konnte nicht zugeordnet werden",
        });

        // move "Alle Produkte" to front for Benutzerdefinierte Produkte (Spezialprodukte)
        const index = store.productCategories[store.curShop.url].findIndex(
            (e) => e.slug == "alle-produkte"
        );
        if (index !== -1) {
            const [element] = store.productCategories[store.curShop.url].splice(
                index,
                1
            ); // remove el from array
            store.productCategories[store.curShop.url].unshift(element); // add to front
        }
    }
    function handleCostlyResponse(response) {
        if (response.status == "rejected") {
            console.error(response);
        } else {
            for (const entry of response) {
                let productTable = entry.meta_data.find(
                    (m) => m.key == "_product_table"
                )?.value;
                if (productTable) {
                    let allParts = JSON.parse(
                        productTable.replaceAll(`\\\"`, `"`)
                    );

                    let shorthand = allParts[0].product;
                    store.costlyProducts[store.curShop.url].push(shorthand);
                }
            }
        }
    }
    function handleHotProductsResponse(response) {
        response = response.map((e) => e.id);
        store.hotOrDrinkIds[store.curShop.url] = response;
    }
    function handleServiceProductsResponse(response) {
        response = response.map((e) => e.id);
        store.personnelOrDishIds[store.curShop.url] = response;
    }

    const requests = [];

    if (components_url)
        requests.push({
            url: components_url,
            handler: handleComponentsResponse,
        });
    if (categories_url)
        requests.push({
            url: categories_url,
            handler: handleCategoriesResponse,
        });
    if (costlyProducts_url)
        requests.push({
            url: costlyProducts_url,
            handler: handleCostlyResponse,
        });
    if (hotProducts_url)
        requests.push({
            url: hotProducts_url,
            handler: handleHotProductsResponse,
        });
    if (serviceProducts_url)
        requests.push({
            url: serviceProducts_url,
            handler: handleServiceProductsResponse,
        });

    // Map each request to a promise and use Promise.allSettled to fetch all data
    Promise.allSettled(
        requests.map((request) => fetchAllPages(request.url))
    ).then((results) => {
        results.forEach((result, index) => {
            // Call the corresponding handler for this request's response
            requests[index].handler(result.value);
        });
    });

    // fetching all entries
    // const url = website + "/wp-json/wc/v3/orders?consumer_key=" + ck + "&consumer_secret=" + cs + "&order_date=" + date + "&status=processing,completed,unpaid-invoice&per_page=100";
    const url =
        "https://" +
        store.curShop.url +
        "/wp-json/wc/v3/orders?status=processing,completed,unpaid-invoice&per_page=100&order_date=" +
        date;

    let response = await fetchAllPages(url);
    checkForCancellation(response, date, playSound);
    await transformJsonToAlpine(response, playSound);
    setupProductsAndTotals();

    store.currentlyProcessingOrdersFor = null;
    store.showTable = true;
    // hacky timeout to avoid the function being called when table isnt fully visible yet
    setTimeout(() => {
        displayPackedProducts();
        displayFinishedProcesses();
    }, 40);
    setTimeout(() => {
        displayCurrentProcesses();
    }, 55);
    setTimeout(() => {
        initHoldEventListeners();
    }, 70);
    setTimeout(() => {
        store.loading = false;
        checkForStammkunden();
    }, 200);

    if (!wcInterval) {
        wcRealtimeInit();
    }
}

// helper to take care of multiple page fetch
async function fetchAllPages(url) {
    const store = Alpine.store("refueat");

    let curPage = 1;
    let totalPages = null;
    let totalResponse = [];

    do {
        await fetch(`${url}&page=${curPage}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + store.auth.jwts[store.curShop.url],
            },
        })
            .then((response) => {
                if (!totalPages) {
                    totalPages = parseInt(
                        response.headers.get("x-wp-totalpages"),
                        10
                    );
                }
                return response.json();
            })
            .then(
                (response) => (totalResponse = totalResponse.concat(response))
            )
            .catch((err) => {
                console.error(err);
                totalResponse = { data: { status: 500, error: err } };
            });

        curPage++;
    } while (curPage <= totalPages);

    return totalResponse;
}

function checkForCancellation(data, date, playSound) {
    const store = Alpine.store("refueat");
    const shop = store.curShop;
    // if there is no data in this shop yet, we dont do anything
    if (!store.orderData[shop.url]) return;

    console.log("checking for cancellation");
    if (data.data) {
        // this could indicate a faulty fetch...
        if (data.data.status != 200) {
            return;
        }
    }

    let todayISO = new Date().toLocaleDateString("sv");

    // only do this for dates that are today or in the future
    if (date < todayISO) return;
    if (!store.orderData[shop.url][date]) return;

    // check if fetch doesnt contain some order anymore - cancelled!
    for (const order of store.orderData[shop.url][date]) {
        const id = order.id;
        // check if this id was in the fetched data!
        const dataIDs = data.map((o) => o.id);
        if (!dataIDs.includes(id)) {
            if (!order.cancelled) {
                console.log("an order was cancelled!");
                console.log("order id: " + id);
                order.cancelled = true;
                if (playSound) notificationSound.play();
            }
        }
    }
    return;
}

async function transformJsonToAlpine(data, playSound) {
    console.log("transformJsonToAlpine called");
    const store = Alpine.store("refueat");
    const shop = store.curShop;

    // if store has no data at all, initialize it - might be a duplication but better safe than sorry
    if (!store.orderData[shop.url]) store.orderData[shop.url] = {};

    if (typeof data === "object" && !Array.isArray(data)) {
        // faulty fetch, we dont do anything here
        console.error("the fetched response did not provide correct data.");
        return;
    }

    // loop through fetched orders
    for (const order of data) {
        const orderNumber = order.number;
        const [time, timeSlot] = getTimeFromOrder(order);
        const shippingMethod = getDeliveryNameFromOrder(order);
        const paymentMethod = order.payment_method_title;

        // find day and corresponding orderData array
        let date;
        if (order.delivery_type == "delivery") {
            date = order.meta_data.find((m) => m.key == "delivery_date").value;
        } else {
            date = order.meta_data.find((m) => m.key == "pickup_date").value;
        }

        // if store has no data for the found date, initialize it
        if (!store.orderData[shop.url][date])
            store.orderData[shop.url][date] = [];

        // if the order information is already present, we dont need to add it again
        let existingOrder = null;
        existingOrder = store.orderData[shop.url][date].find(
            (o) => o.id == order.id
        );

        // check whether found order was modified OR falsely labeled as cancelled, if so replace
        if (
            existingOrder &&
            (existingOrder.modifiedAt != order.date_modified_gmt ||
                existingOrder.cancelled)
        ) {
            console.log("an order has changed.", order.id);
            store.orderData[shop.url][date] = store.orderData[shop.url][
                date
            ].filter((o) => o.id != order.id);

            existingOrder = null;
        }

        // if we didnt find this order or it needs to be replaced, its new and we add it!
        if (!existingOrder) {
            if (playSound) notificationSound.play();
            let orderEntry = {};
            orderEntry.menus = {};

            let fetches = [];

            // loop through items within one order
            for (let j = 0; j < order.line_items.length; j++) {
                const product = order.line_items[j];
                let id = null;

                // check the referenced products by fetching them from woocommerce and looking at their product_table
                if (product.referenced_products_table) {
                    const referenced_products_table = JSON.parse(
                        product.referenced_products_table.replaceAll(
                            "&quot;",
                            `"`
                        )
                    );
                    const productId = product.product_id;
                    let variationsToFetch = [];
                    let variationsAndQtys = [];
                    for (const key in referenced_products_table) {
                        const variation = referenced_products_table[key];
                        const variationId = variation.product;
                        const variationQty = variation.qty;
                        variationsToFetch.push(variationId);
                        variationsAndQtys.push([variationId, variationQty]);
                    }

                    for (const variationId of variationsToFetch) {
                        // this is actually the wrong productId, idk why it still fetches correctly
                        // it is the id of the product, that references another (i.e. the id of a catering), NOT the referenced id
                        const url =
                            "https://" +
                            store.curShop.url +
                            "/wp-json/wc/v3/products/" +
                            productId +
                            "/variations/" +
                            variationId +
                            "?consumer_key=ck_bb0e4669f8fe498b3cae598c3c1037bdd72f0b28&consumer_secret=cs_48290dd472dfd6a059d87e172e8812a3fc72741d";

                        let found = store.fetchedVariations.find(
                            (v) => v.vId == variationId
                        );
                        // only fetch, if we havent fetched this id yet
                        if (!found) {
                            fetches.push(
                                fetch(url, {
                                    method: "GET",
                                    // headers: {
                                    //     Authorization:
                                    //         "Bearer " +
                                    //         store.auth.jwts[store.curShop.url],
                                    // },
                                    // mode: "cors",
                                })
                                    .then((response) => response.json())
                                    .then((response) => {
                                        store.fetchedVariations.push({
                                            vId: variationId,
                                            responseJson: response,
                                        });
                                    })
                                    .catch((err) => console.error(err))
                            );
                        }
                    }
                    // wait for all needed fetches for this referenced_products_table
                    let fetchingPromise = Promise.all(fetches).then(
                        function () {
                            for (const [
                                variationId,
                                variationQty,
                            ] of variationsAndQtys) {
                                let fetched = store.fetchedVariations.find(
                                    (v) => v.vId == variationId
                                );
                                if (fetched) {
                                    let productTable = fetched.responseJson
                                        .meta_data
                                        ? fetched.responseJson.meta_data.find(
                                              (item) =>
                                                  item.key === "_product_table"
                                          )
                                        : null;

                                    if (productTable) {
                                        processProductTable(
                                            fetched.responseJson.id,
                                            productTable.value,
                                            product,
                                            orderEntry,
                                            variationQty,
                                            true // we pass true as last argument because the product in question is a package NOT the actual product -> we look at varId instead!
                                        );
                                    } else {
                                        // TODO?: handle error / unfinished data structure
                                        console.log(fetched.responseJson);
                                        console.error(
                                            "FEHLER! Prüfe die referenzierten Produkte im Produkt '" +
                                                product.name +
                                                "'"
                                        );
                                        console.error(
                                            "product_id: " + product.product_id
                                        );
                                        console.error(
                                            "variation_id: " +
                                                product.variation_id
                                        );
                                        // console.error(url)
                                    }
                                    let components = fetched.responseJson
                                        .meta_data
                                        ? fetched.responseJson.meta_data.find(
                                              (item) =>
                                                  item.key ===
                                                  "_product_components_table"
                                          )
                                        : null;
                                    if (components) {
                                        components = JSON.parse(
                                            components.value.replaceAll(
                                                `\\\"`,
                                                `"`
                                            )
                                        );
                                        if (
                                            !store.productComponents[
                                                variationId
                                            ]
                                        ) {
                                            components =
                                                Object.values(components);

                                            for (const component of components) {
                                                let slug = component.component;
                                                let post = store.componentPosts[
                                                    store.curShop.url
                                                ].find((p) => p.slug == slug);
                                                component.component = post
                                                    ? post.title.rendered
                                                    : "";
                                            }

                                            let obj = {
                                                components: components,
                                            };
                                            store.productComponents[
                                                variationId
                                            ] = obj;
                                        }
                                    }
                                    let image = fetched.responseJson.image;
                                    if (image) {
                                        store.productImages[variationId] =
                                            image.src;
                                    }
                                }
                            }
                        }
                    );
                    await fetchingPromise;
                } else if (product.product_table) {
                    id = product.variation_id
                        ? product.variation_id
                        : product.product_id;
                    try {
                        processProductTable(
                            id,
                            product.product_table,
                            product,
                            orderEntry
                        );
                    } catch (error) {
                        console.log(product);
                        console.error(error);
                    }
                    // this might set it more than once but its not an issue
                    let image = product.image;
                    if (image) {
                        store.productImages[id] = image.src;
                    }
                    // else case might become irrelevant once product structure in backend is complete
                } else {
                    id = product.variation_id
                        ? product.variation_id
                        : product.product_id;
                    const shorthand = product.parent_name
                        ? product.parent_name
                        : product.name;
                    orderEntry.menus[shorthand] = product.quantity;
                    // this gives an error for gutschein bestellungen!!
                    orderEntry.menus[shorthand].id = product.product_id;
                }

                if (product.product_components_table) {
                    if (!store.productComponents[id]) {
                        let components = JSON.parse(
                            product.product_components_table.replaceAll(
                                "&quot;",
                                `"`
                            )
                        );
                        components = Object.values(components);

                        for (const component of components) {
                            let slug = component.component;
                            let name = store.componentPosts[
                                store.curShop.url
                            ].find((p) => p.slug == slug);
                            if (name) component.component = name.title.rendered;
                        }

                        let obj = {
                            components: components,
                        };
                        const id = product.variation_id
                            ? product.variation_id
                            : product.product_id;
                        store.productComponents[id] = obj;
                    }
                }
            }

            // if we didnt fetch we dont wait here
            let fetchingPromise = Promise.all(fetches).then(async function () {
                // save order information

                orderEntry.id = order.id;
                orderEntry.line_items = order.line_items; // used to check for service/hot/foodbike

                const s = order.shipping;
                const b = order.billing;
                orderEntry.shippingAddress = `${s.address_1} <br> ${s.address_2} <br> ${s.postcode} ${s.city}`;
                orderEntry.company = s.company || "";

                orderEntry.phone = order.billing.phone || "";
                orderEntry.customerEmail = order.billing.email || "";
                orderEntry.customerName =
                    (s.first_name || "") + " " + (s.last_name || "");
                if (orderEntry.customerName == " ")
                    orderEntry.customerName =
                        (b.first_name || "") + " " + (b.last_name || ""); // see if name is in billing instead

                let cN = order.customer_note || "";
                if (cN.includes("[:de]")) {
                    const regex = /\[:de\](.*?)(?:\[:en\]|\[:\])/s;
                    const onlyGermanCN = cN.match(regex);
                    if (onlyGermanCN && onlyGermanCN.length > 1)
                        cN = onlyGermanCN[1];
                }
                orderEntry.customerNote = cN;
                orderEntry.isStammkunde = null;

                orderEntry.orderNumber = orderNumber;
                orderEntry.time = time;
                orderEntry.timeSlot = timeSlot;
                orderEntry.shippingMethod = shippingMethod;
                orderEntry.paymentMethod = paymentMethod;
                orderEntry.total =
                    order.total.replace(".", ",") + order.currency_symbol;
                orderEntry.distance = "";
                orderEntry.createdAt = order.date_created_gmt;
                orderEntry.modifiedAt = order.date_modified_gmt;
                orderEntry.cancelled = false;

                // set flags for filters
                orderEntry.foodbike = false;
                orderEntry.service = false;
                orderEntry.hot = false;
                // check for foodbike/personnel/dishes in line_items
                for (const item of orderEntry.line_items) {
                    if (item.name.includes("Foodbike"))
                        orderEntry.foodbike = true;
                    if (
                        store.personnelOrDishIds[store.curShop.url].includes(
                            item.product_id
                        )
                    )
                        orderEntry.service = true;
                    if (
                        store.hotOrDrinkIds[store.curShop.url].includes(
                            item.product_id
                        )
                    )
                        orderEntry.hot = true;
                }

                // set boxes for order
                let metaBoxes = order.meta_data.find((o) => o.key == "boxes");
                if (metaBoxes) {
                    orderEntry.boxes = metaBoxes.value;
                }

                // read out google maps info from metas
                if (shippingMethod.includes("Lieferung")) {
                    let dist_meta = order.meta_data.find(
                        (item) => item.key === "delivery_distance"
                    );
                    let bear_meta = order.meta_data.find(
                        (item) => item.key === "delivery_bearing"
                    );
                    orderEntry.distance = dist_meta ? dist_meta.value : "";
                    orderEntry.bearing = bear_meta ? bear_meta.value : "";
                }

                store.orderData[shop.url][date].push(orderEntry);
            });
            await fetchingPromise; // important
        }
    }

    sortOrderData();
    console.log("return orderData from transformJsonToAlpine");
    return;
}

function processProductTable(
    varId,
    productTable,
    product,
    orderEntry,
    variationQty = 1,
    useVarIdForMenusId = false
) {
    const store = Alpine.store("refueat");

    // console.log(productTable)
    let allParts = JSON.parse(productTable.replaceAll(`\\\"`, `"`));
    for (const index in allParts) {
        const part = allParts[index];
        const id = varId;
        const size = part.size;
        let shorthand = part.product;

        // identify each spezialprodukt separately!
        if (shorthand == "Spezialprodukt") {
            shorthand = shorthand + "-" + store.specialProductIdCounter;
            store.specialProductIdCounter++;
        }

        if (!store.nameIds[shorthand]) {
            // if the name occurred for the first time, we add the object for it and push the id
            store.nameIds[shorthand] = [];
            store.nameIds[shorthand].push([id, size]);
        } else {
            // if the object exists we add the id to the array, if it wasnt there yet
            // check for array in array of arrays hack
            let arr1 = JSON.stringify(store.nameIds[shorthand]);
            let arr2 = JSON.stringify([id, size]);
            if (arr1.indexOf(arr2) == -1) {
                store.nameIds[shorthand].push([id, size]);
            }
        }
        if (!orderEntry.menus[shorthand]) {
            orderEntry.menus[shorthand] = {};
            orderEntry.menus[shorthand].id = product.product_id;
            orderEntry.menus[shorthand].sku = product.sku;
            if (useVarIdForMenusId) orderEntry.menus[shorthand].id = varId;
        }
        if (!orderEntry.menus[shorthand][size])
            orderEntry.menus[shorthand][size] = 0;
        // quantity consist of: amount of orders for this product * amount of sub-products within this product * amount of a single sub-product
        // example-order: 2x Catering für 50
        // amount of orders: 2x
        // amount of sub-products (pitabrot): 100x 1er Variation
        // amount of single sub-product: 1x in 1er Variation
        //      => 200x
        orderEntry.menus[shorthand][size] +=
            parseInt(part.qty) * product.quantity * variationQty;
    }
}

function setupProductsAndTotals() {
    console.log("setupProductsAndTotals called");
    const store = Alpine.store("refueat");

    // add orderQuantities in the the structure of
    // {
    // 	"7893": {
    // 	  "M": 	1,
    // 	  "XL": 2
    // 	}
    // }
    // to productQuantities per product

    // init if doesnt exist
    if (!store.productQuantities[store.curShop.url])
        store.productQuantities[store.curShop.url] = {};
    if (!store.productQuantities[store.curShop.url][store.curDate])
        store.productQuantities[store.curShop.url][store.curDate] = {};

    // for some reason this is needed for a third time
    if (!store.orderData[store.curShop.url][store.curDate])
        store.orderData[store.curShop.url][store.curDate] = [];

    for (const order of store.orderData[store.curShop.url][store.curDate]) {
        for (const shorthand in order.menus) {
            const productQtys = order.menus[shorthand];

            let found =
                shorthand in
                store.productQuantities[store.curShop.url][store.curDate];
            if (!found) {
                let p = {
                    orders: {},
                    orderQtysStrings: {},
                    cancelledOrders: {},
                };
                p.orders[order.orderNumber] = productQtys;
                p.orderQtysStrings[order.orderNumber] =
                    quantitiesToString(productQtys);
                p.cancelledOrders[order.orderNumber] = order.cancelled;
                store.productQuantities[store.curShop.url][store.curDate][
                    shorthand
                ] = p;
            } else {
                if (
                    !store.productQuantities[store.curShop.url][store.curDate][
                        shorthand
                    ].orders[order.orderNumber]
                ) {
                    store.productQuantities[store.curShop.url][store.curDate][
                        shorthand
                    ].orders[order.orderNumber] = productQtys;
                    store.productQuantities[store.curShop.url][store.curDate][
                        shorthand
                    ].orderQtysStrings[order.orderNumber] =
                        quantitiesToString(productQtys);
                }
                store.productQuantities[store.curShop.url][store.curDate][
                    shorthand
                ].cancelledOrders[order.orderNumber] = order.cancelled;
            }
        }
    }

    // add totals object
    for (let shorthand in store.productQuantities[store.curShop.url][
        store.curDate
    ]) {
        const product =
            store.productQuantities[store.curShop.url][store.curDate][
                shorthand
            ];
        product.totalQtys = {};
        for (const orderNumber in product.orders) {
            // if this order was cancelled, dont add it to totals!
            if (product.cancelledOrders[orderNumber]) continue;
            for (const size in product.orders[orderNumber]) {
                if (size == "id") {
                    product.id = product.orders[orderNumber].id;
                    delete product.orders[orderNumber].id; // this is not needed in this object anymore
                    continue;
                }
                if (size == "sku") {
                    product.sku = product.orders[orderNumber].sku;
                    delete product.orders[orderNumber].sku; // this is not needed in this object anymore

                    // if the product is Benutzerdefiniert, we need to access the meta info...
                    if (product.sku.includes("EXTRA-")) {
                        let order = store.orderData[store.curShop.url][
                            store.curDate
                        ].find((o) => o.orderNumber == orderNumber);
                        if (order) {
                            // look for the first match in sku, that wasnt found yet, with same quantity amount
                            let lineItem = order.line_items.find(
                                (li) =>
                                    li.sku == product.sku &&
                                    !store.savedSpecialProducts.includes(
                                        li.id
                                    ) &&
                                    li.quantity ==
                                        product.orders[orderNumber][""]
                            );

                            // keep track of the found item
                            if (lineItem) {
                                store.savedSpecialProducts.push(lineItem.id); // save item id, to not fetch same item again
                            }

                            let metaData = lineItem?.meta_data.find(
                                (m) => m.key == "Name"
                            );
                            if (metaData) product.customName = metaData.value;
                            else {
                                metaData = lineItem?.meta_data.find(
                                    (m) => m.key == "name"
                                );
                                if (metaData)
                                    product.customName = metaData.value;
                            }
                        }
                    }
                    continue;
                }
                const sizeQty = product.orders[orderNumber][size];
                if (size in product.totalQtys) {
                    product.totalQtys[size] += sizeQty;
                } else {
                    product.totalQtys[size] = sizeQty;
                }
            }
        }
        product.totalQtysString = quantitiesToString(product.totalQtys);
    }

    for (const order of store.orderData[store.curShop.url][store.curDate]) {
        for (const shorthand in order.menus) {
            // data cleanup because its in the wrong place to begin with
            delete order.menus[shorthand].id;
            delete order.menus[shorthand].sku;
        }
    }

    // init if doesnt exist
    if (!store.sortedProductQuantities[store.curShop.url])
        store.sortedProductQuantities[store.curShop.url] = {};

    // sort into array so that each order is filled before next is listed (old sorting logic)
    let arr = Object.entries(
        store.productQuantities[store.curShop.url][store.curDate]
    );
    let sortedArr = [];
    for (const order of store.orderData[store.curShop.url][store.curDate]) {
        let orderNo = order.orderNumber;
        let subArr = [];
        for (const [pShorthand, pEntry] of arr) {
            if (Object.keys(pEntry.orders).includes(orderNo)) {
                let product = [pShorthand, pEntry];
                const found = sortedArr.find((e) => e[0] == pShorthand);
                // only put it in the array if its not already inserted by a prior order
                if (!found) {
                    subArr.push(product);
                }
            }
        }
        // sort entries per order alphabetically
        subArr.sort();
        sortedArr.push(...subArr);
    }
    store.sortedProductQuantities[store.curShop.url][store.curDate] = sortedArr;

    // setup a mapping of product_id to category (simplicity: first listed category)
    const productToCategoryMap = {};
    for (const order of store.orderData[store.curShop.url][store.curDate]) {
        for (const lineItem of order.line_items) {
            // iterating through line items does NOT work when we are dealing with a catering package... whomp whomp
            // if thats the case (condition: referenced_products_table is set), we look at store.fetchedVariations
            // and set their respective categories!
            if (lineItem.referenced_products_table) {
                const referenced_products_table = JSON.parse(
                    lineItem.referenced_products_table.replaceAll("&quot;", `"`)
                );
                for (const key in referenced_products_table) {
                    const variation = referenced_products_table[key];
                    const variationId = variation.product;
                    const fetchedVariation = store.fetchedVariations.find(
                        (fv) => fv.vId == variationId
                    );
                    if (fetchedVariation) {
                        productToCategoryMap[variationId] = fetchedVariation
                            .responseJson.categories
                            ? fetchedVariation.responseJson.categories[0]
                            : "no-category";
                    } else {
                        productToCategoryMap[variationId] = "no-category";
                    }
                }
            } else {
                productToCategoryMap[lineItem.product_id] = lineItem.categories
                    ? lineItem.categories[0]
                    : "no-category";
            }
        }
    }

    store.productQuantitiesByCategory = {};
    // init (-if doesnt exist-)
    if (!store.productQuantitiesByCategory[store.curShop.url])
        store.productQuantitiesByCategory[store.curShop.url] = {};
    if (!store.productQuantitiesByCategory[store.curShop.url][store.curDate])
        store.productQuantitiesByCategory[store.curShop.url][store.curDate] =
            {};

    // Group productQuantities by category
    for (const [shorthand, product] of store.sortedProductQuantities[
        store.curShop.url
    ][store.curDate]) {
        const category = productToCategoryMap[product.id];

        if (category) {
            if (
                !store.productQuantitiesByCategory[store.curShop.url][
                    store.curDate
                ][category]
            ) {
                store.productQuantitiesByCategory[store.curShop.url][
                    store.curDate
                ][category] = [];
            }
            store.productQuantitiesByCategory[store.curShop.url][store.curDate][
                category
            ].push([shorthand, product]);
        }
    }
}

async function checkForStammkunden() {
    const store = Alpine.store("refueat");
    const requests = [];
    for (const order of store.orderData[store.curShop.url][store.curDate]) {
        if (order.isStammkunde == null) {
            let stammkunde_url =
                "https://" +
                store.curShop.url +
                "/wp-json/refueat/v1/ordercount-by-id?order_id=" +
                order.id;

            requests.push(
                fetch(stammkunde_url, {
                    method: "GET",
                    headers: {
                        Authorization:
                            "Bearer " + store.auth.jwts[store.curShop.url],
                    },
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if (response.length > 3) {
                            order.isStammkunde = true;
                        } else {
                            order.isStammkunde = false;
                        }
                        order.previousOrders = response;
                    })
            );
        }
    }
}

/************* HELPERS ***************/

function getTimeFromOrder(order) {
    if (!order.meta_data) {
        console.error("this order has no meta_data");
    } else {
        let time = "";
        if (order.delivery_type == "delivery") {
            const delivery_index = order.meta_data.findIndex(
                (item) => item.key == "delivery_time"
            );
            if (delivery_index != -1)
                time = order.meta_data[delivery_index].value;
        } else if (order.delivery_type == "pickup") {
            const pickup_index = order.meta_data.findIndex(
                (item) => item.key == "pickup_time"
            );
            if (pickup_index != -1) time = order.meta_data[pickup_index].value;
        }
        if (time) {
            const regex = /^\d{1,2}:\d\d/gm;

            let strippedTime = regex.exec(time)[0];

            if (strippedTime) {
                let hour = Number(strippedTime.substring(0, 2)) - 1;
                let rest = strippedTime.substring(2, 5);
                hour = hour.toLocaleString("de", { minimumIntegerDigits: 2 });

                strippedTime = hour + rest;
            }

            return [strippedTime, time];
        }
        return ["-", "keine Angabe"];
    }
}

function getDeliveryNameFromOrder(order) {
    if (order.delivery_type == "delivery") {
        return "Lieferung";
    } else if (order.delivery_type == "pickup") {
        return "Abholung";
    }
}

function sortOrderData() {
    const store = Alpine.store("refueat");
    const dates = Object.keys(store.orderData[store.curShop.url]);
    for (const date of dates) {
        store.orderData[store.curShop.url][date] = store.orderData[
            store.curShop.url
        ][date].sort(function (a, b) {
            if (a.time != b.time) return a.time.localeCompare(b.time);
            else return a.createdAt.localeCompare(b.createdAt);
        });
    }
}

function quantitiesToString(qtys) {
    let str = "";
    for (const size in qtys) {
        if (size == "id" || size == "sku") continue;
        const qty = qtys[size];
        str += qty + " x " + size + "\n";
    }
    return str;
}

let wcInterval;

function wcRealtimeInit() {
    wcInterval = setInterval(() => {
        console.log("refresh current day");
        processOrders(true);
    }, 60 * 1000 * 10); // 10 minutes
}
