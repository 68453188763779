// import * as Sentry from "@sentry/browser";

// Sentry.init({
//     dsn: "https://e942caaaa18146e5a535d412565f54d4@o1142314.ingest.sentry.io/4505427661881344",
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//             // tracePropagationTargets: [
//             //     "localhost",
//             //     /^https:\/\/yourserver\.io\/api/,
//             // ],
//         }),
//         new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

import { registerSW } from "virtual:pwa-register";
registerSW({ immediate: true });

// check for update every 30 seconds, will trigger reload
setInterval(async () => {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
            registration.update();
        }
    });
}, 30 * 1000);

import "./style.scss";
import Alpine from "alpinejs";

window.Alpine = Alpine;

import "./realtime.js";
import "./alpineInit.js";
import "./fetchOrders.js";
import "./interaction.js";

console.log("loaded main");

Alpine.start();
