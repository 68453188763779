export function initHoldEventListeners() {
    let cells = document.querySelectorAll(".cell");

    let to = null;

    for (let cell of cells) {
        cell.addEventListener("mousedown", function (e) {
            if (cell.classList.contains("packed")) {
                to = setTimeout(() => {
                    // open dialog to revert packed product
                    showPackageRevertDialog(e.target);
                }, 1500);
            } else if (cell.classList.contains("finished")) {
                to = setTimeout(() => {
                    // open dialog to revert finished product
                    showRevertDialog(e.target);
                }, 1500);
            }
        });

        cell.addEventListener("mouseup", function (e) {
            if (
                cell.classList.contains("finished") ||
                cell.classList.contains("packed")
            ) {
                if (to) clearTimeout(to);
            }
        });
    }
}

/******************** DISPLAYING CELLS ********************/

export function displayCurrentProcesses() {
    const store = Alpine.store("refueat");

    store.preparingCells = [];
    store.fullyPreparingRowsTotalCells = [];
    store.preparingWorkers = [];

    for (const process of store.currentProcesses) {
        if (!store.time) store.initTimer();
        let workerPreparingCells = [];
        for (const productShorthand in process.products_orders_quantities) {
            let prodObj = process.products_orders_quantities[productShorthand];
            for (const orderNumber in prodObj) {
                const cell = document.querySelector(
                    "[data-product-name='" +
                        CSS.escape(productShorthand) +
                        "'][data-order-number='" +
                        orderNumber +
                        "']"
                );
                workerPreparingCells.push(cell);
                store.preparingCells.push(cell);
            }

            const totalsCells = document.querySelectorAll(
                "tr td.totals[data-product-name='" +
                    CSS.escape(productShorthand) +
                    "']"
            );
            // check if entire row is being prepared now, if so, we also mark totals cell
            const rowCells = document.querySelectorAll(
                "[data-product-name='" +
                    CSS.escape(productShorthand) +
                    "'][data-filled]"
            );
            const preparingRowCells = store.preparingCells.filter(
                (c) => c?.dataset.productName == productShorthand
            );
            const finishedRowCells = document.querySelectorAll(
                "[data-product-name='" +
                    CSS.escape(productShorthand) +
                    "'][data-filled].finished"
            );

            if (
                rowCells.length ==
                preparingRowCells.length + finishedRowCells.length
            ) {
                for (const totalsCell of totalsCells) {
                    store.fullyPreparingRowsTotalCells.push(totalsCell);
                }
            }
        }

        let workerId = process.employee;
        let worker = store.workers.find((w) => w.id == workerId);
        worker.preparingProductsOrdersQuantities =
            process.products_orders_quantities;
        worker.preparingCells = workerPreparingCells;
        if (
            process.breaks &&
            !process.breaks[process.breaks.length - 1].ended_at
        ) {
            // worker is currently on a break
            worker.isPreparing = false;
            worker.isPausing = true;
        } else {
            worker.isPreparing = true;
            store.preparingWorkers.push(worker);
        }
    }
}

export function displayFinishedProcesses() {
    const store = Alpine.store("refueat");

    store.finishedCells = [];
    store.fullyFinishedRowsTotalCells = [];

    for (const process of store.finishedProcesses) {
        for (const pShorthand in process.products_orders_quantities) {
            for (const oNo in process.products_orders_quantities[pShorthand]) {
                const cell = document.querySelector(
                    "[data-product-name='" +
                        CSS.escape(pShorthand) +
                        "'][data-order-number='" +
                        oNo +
                        "']"
                );
                store.finishedCells.push(cell);
            }

            const totalsCells = document.querySelectorAll(
                "tr td.totals[data-product-name='" +
                    CSS.escape(pShorthand) +
                    "']"
            );
            // check if entire row is finished, if so, we also mark totals cell
            const rowCells = document.querySelectorAll(
                "[data-product-name='" +
                    CSS.escape(pShorthand) +
                    "'][data-filled]"
            );
            const finishedRowCells = store.finishedCells.filter(
                (c) => c && c.dataset.productName == pShorthand
            );

            if (rowCells.length == finishedRowCells.length) {
                for (const totalsCell of totalsCells) {
                    store.fullyFinishedRowsTotalCells.push(totalsCell);
                }
            }
        }
    }
}

export function displayPackedProducts() {
    const store = Alpine.store("refueat");

    store.packedCells = [];
    store.fullyPackedRowsTotalCells = [];

    for (const pack of store.packedProducts) {
        for (const pShorthand in pack.packed_products_orders) {
            for (const oNo of pack.packed_products_orders[pShorthand]) {
                const cell = document.querySelector(
                    "[data-product-name='" +
                        CSS.escape(pShorthand) +
                        "'][data-order-number='" +
                        oNo +
                        "']"
                );
                store.packedCells.push(cell);
            }

            const totalsCells = document.querySelectorAll(
                "tr td.totals[data-product-name='" +
                    CSS.escape(pShorthand) +
                    "']"
            );
            // check if entire row is packed, if so, we also mark totals cell
            const rowCells = document.querySelectorAll(
                "[data-product-name='" +
                    CSS.escape(pShorthand) +
                    "'][data-filled]"
            );
            const packedRowCells = store.packedCells.filter(
                (c) => c && c.dataset.productName == pShorthand
            );

            if (rowCells.length == packedRowCells.length) {
                for (const totalsCell of totalsCells) {
                    store.fullyPackedRowsTotalCells.push(totalsCell);
                }
            }
        }
    }
}
